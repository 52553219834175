@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

$txt-font:Source Sans Pro;
$header-height: 130px;
$search-icon-height: 90px;
$blue-nav-height: 40px;
$gray-color: #f7f7f7;
$black-color:#2C2C2C;
$font-wght-7:700;

html,body {
  font-family:$txt-font;
  background-color: $white;
  font-size: 14px;
  @media (min-width: map-get($grid-breakpoints, xl)) {
    font-size: 16px;
  }

}

.fa {
  font-weight: 900 !important;
  font-family: "Font Awesome 5 Free" !important;
}

a:target {
  margin-top: -200px !important;
  padding-top: 200px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -160px !important;
    padding-top: 160px !important;
  }
}
html.logged-in a:target {
  margin-top: -250px !important;
  padding-top: 250px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -210px !important;
    padding-top: 210px !important;
  }
}


.fixed_header {
  position: fixed;
  z-index: 3;
  width: 100%;
  background: $white;
  height: $header-height;

  .multilang {
    width: $search-icon-height;
    padding: 11px;
    text-align: center;
    .full-lang-name {
      display: none;
    }
    .lang_link {
      display: block;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      &.lang_link_hr {
        background-image: url('../../../icons/flag-hr.svg');
      }
      &.lang_link_en {
        background-image: url('../../../icons/flag-en.svg');
      }
    }
  }


  .big_screen {
    height: $header-height;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
  }

  .mobile_screen {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
    .multilang {
      padding-left: 0;
      .lang_link {
        background-position: left;
      }
    }
    .mobile_nav_top {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      img.header_logo {
        height: 60px;
      }
      img.login {
        height: 40px;
        margin: 8px;
      }
      .hamburger {
        font-size: 24px;
      }
    }
    .mobile_nav_bottom {
      height: 60px;
      background-color: $gray-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      img {
        height: 60px;
      }
    }
  }

  .fixed_nav {
    height: $blue-nav-height;
    background-color: theme-color("primary");
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 600;
    a {
      text-transform: uppercase;
      color: $white;
    }
  }

  .quilt_nav {
    background-color: $gray-color;
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
    .horizontal_nav_div {
      width: 100%;
    }
    .logo {
      height: $search-icon-height;
        img {
          //height: calc(#{$search-icon-height} - 2px);
          height: 60px;
          margin: 15px;
          margin-left: 100px;
          margin-right: 50px;
          @media (max-width: map-get($grid-breakpoints, xl)) {
            margin-left: 60px;
            margin-right: 20px;
          }
        }
    }
    .navbar-brand {
      display: none;
    }
    nav {
      padding: 0;
      width: 100%;
      .nav_home {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .nav_id_div {
      width: 100%;
    }

    ul.navbar-nav {
      width: 100%;
      height: $search-icon-height;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      li {
        display: flex;
        align-items: center;
        position: static;
        font-weight: 600;

        &:hover {
          background: theme-color("primary");

          a {
            color: white;

          }
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      a {
        display: inline-block;
        padding: 20px;
      }
      a:hover {
        background: theme-color("primary");
        color: white;
        text-decoration: none;
      }

      .card-columns {
        column-count: 3 !important;
        height: auto;


      }





      .submenu li {
        text-transform: none;
        font-weight: 600;
        font-size: 18px;
        display: block;



        a {
          width:100%;

        }
      }

      .mega-dropdown-menu {
        ul {
          list-style-type: none;
          padding-left:24px !important;
          padding-top: 31px;
          padding-bottom:23px;
          padding-right: 24px;
          width:100%;

        }
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 !important;
        border: 0;
        @extend .rounded-0;

        background-color: theme-color("primary");
        a {
          color: $white;
          padding:2%;



        }
      }
    }
  }
}

.above_search {
  width: 90px;
  height: 90px;
  background-color: #7e9db2;

  .search-container {
    margin: 0;
    width: 90px;
    height: 90px;
  }
  label.searchbutton, label.searchbutton:hover {
    width: 90px;
    height: 90px;
    background-image: url('../../../icons/search_icon.png');
    background-color: #7e9db2;
    background-repeat: no-repeat;
    background-position-x: right;
    color: transparent !important;
    cursor: pointer;
    position: absolute;
    right: 0;
    transition-duration: 0.4s;

  }

  label.searchbutton:hover, label.searchbutton:focus-within {
    width: 500px;
  }

  input#searchright {
    height: 50px;
    right: 100px;
    top: 20px;
    border: 0;
    background: white;
  }
  .unibox-suggest-cluster  {
    h4 {
      color: black;
    }
  }
}

#cms_area_left {

  .sidebar
  {

    margin-bottom: 50px;

    li.nav-item {
      white-space: nowrap;
    }
    .nav-link {
      display: inline-block;
      color: black;
      font-weight: 600;
      text-transform: uppercase;
      white-space: normal;
    }

    .submenu .nav-link {
      font-weight: 300;
      color: #6a6a6a;
    }

    .nav-link.current_page {
      color: theme-color("primary");
    }

    .collapse-link, .collapse-nochildren {
      color: #6a6a6a;
      font-size: 20px;
      text-decoration: none;
      display: inline-block;
      width: 20px;
      padding: 0 5px;

      &::after {
        content: "-";
      }

      i {
        display: none;
      }
    }

    .collapse-link.collapsed {
      &::after {
        content: "›";
      }
    }

    .collapse-nochildren {
      &::after {
        content: " ";
      }
    }


    .public_page.present_page {
      border-left: 2px solid green;
      border-right: none;
      border-bottom: none;
    }
    .public_page.not_present_page {
      border-left: 2px dotted green;
      border-right: none;
      border-bottom: none;
    }
    .private_page.present_page {
      border-left: 2px solid red;
      border-right: none;
      border-bottom: none;
    }
    .private_page.not_present_page {
      border-left: 2px dotted red;
      border-right: none;
      border-bottom: none;
    }
  }

}




.scrollable_content {
  padding-top: $header-height;
  background: url('../../../img/bg-image.jpg') no-repeat;
  background-size: 100%;
  .scrollable_white {
    background: $white;
    padding: 30px;
    margin: 30px 0;

    /*maknut padding na malim ekranima*/
    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding: 0 !important;
    }

    .frontpage_portlets > div {
      margin-bottom: 30px;
    }
    .area_video {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-right: 20px;
      }

    }
    .area_carousel {

      .carousel {
        min-height: 600px;
        background-size: cover;
        margin: 0 !important;
        //background: url('../../../img/header.gif');
        background: white;
        padding: 0 !important;
        @media (max-width: map-get($grid-breakpoints, sm)) {
          min-height: 300px !important;
        }
        .carousel-inner img {
          object-fit: cover;
          height: 600px;
          min-width: 100%;

          @media (max-width: map-get($grid-breakpoints, sm)) {
            height: 300px !important;
          }

         
        }

      }
      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-right: 20px;
      }
      .carousel-indicators {
        z-index: 1;
      }

      .carousel-caption {


        background: rgba(0.0,27.1,48.6,.6);
        color: white;
        a {
          color: white;
        }
        margin-bottom:15%;
        @media (max-width: map-get($grid-breakpoints, xl)) {
          margin-bottom: 10%;
        }
        @media (max-width: map-get($grid-breakpoints, lg)) {
          margin-bottom: 5%;
        }
        @media (max-width: map-get($grid-breakpoints, md)) {
          margin-bottom: 0;
        }
        width:60%;
        left:0px;
        z-index: 1;

        h1, p {
          opacity:1 !important;
          text-align: left;
          font-family: $txt-font;
          justify-content: center;
          padding-right:8%;
          padding-left: 8%;

        }
        h1 {
          font-size: 40px;
          font-weight: $font-wght-7;
          @media (max-width: map-get($grid-breakpoints, xl)) {
            font-size: 32px;
          }
          @media (max-width: map-get($grid-breakpoints, lg)) {
            font-size: 28px;
          }
          @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 24px;
          }
          @media (max-width: map-get($grid-breakpoints, sm)) {
            font-size: 20px;
          }


        }
        p {
          font-size: 20px;
          font-weight: 600;
          @media (max-width: map-get($grid-breakpoints, xl)) {
            font-size: 18px;
          }
          @media (max-width: map-get($grid-breakpoints, lg)) {
            font-size: 16px;
          }
          @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 14px;
          }
        }
      }

    }

    .area_quicklinks {

      ul {
        background: $gray-color;
        li a {
          border-bottom: 2px solid white;
          background: $gray-color;
           font-weight: 600;
          color:$black-color;
          text-transform: uppercase;
           padding-left: 10px;

           i {
             color: #a0a0a0;
             padding: 0 10px;
           }
         }
       }
       h3 {
        font-weight: $font-wght-7;
        background: $gray-color;
         margin: 0;
         border-bottom: 2px solid white;
         padding: 10px 36px;

       }
    }

    .area_logolinks {
      background-color: theme-color("primary");
      height: 100%;
      padding: 15px;
      color: white;
      a {
        color: white;
      }
    }
  }
}


.news_show_headlines_render_articles {
  .card {
    height: 350px;
  }
  .card-body {
    padding: 25px;
    padding-left: 29px;
    text-align: justify;
  }
  .card-img-top {
    width: 100%;
    height: 200px !important;
    object-fit: cover !important;
  }
  .card-title {
    max-height: 100px;
  }
  h5 {
    font-weight: $font-wght-7;
    font-size: 21px;
    margin-bottom: 25px;
  }
  .card-content {
    height: 200px;
    padding-right: 42px;
  }
  .cover_link {
    transition-duration: 0.5s;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cover_link:hover {
    background: rgba(0,0,0,0.04);
  }

}

.portlet_news_show_headlines {
  .more_links {
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
    background:theme-color("primary");
    color:white;
    text-transform: uppercase;
  }
  .more_links:hover {
    background: #F0F0F0;
    color:theme-color("primary");
    text-decoration: none;
    transition-duration: 0.3s;
  }
}


.portlet_search {
  div.search_div {
    margin: 15px;
  }
  .search_result {
    padding: 10px 0;
    border-bottom: 1px solid #d0d0d0;
  }
  .search_stats {
    margin: 20px 0;
    color: gray;
  }
  .dropdown-menu {
    padding: 20px;
  }
}

.portlet_ispis_pred {
  td {
    font-size: 14px;
    font-weight: 300;
  }
  .naziv > a {
    font-weight: bold;
  }
  .ui-tabs-panel {
    padding: 0 !important;
  }
  td {
    padding: 5px;
  }
}

.portlet_news {
  font-size: 16px !important;
  .cover_link {
    z-index: 10;
    transition-duration: 0.5s;
    display: block;
    position: absolute;
    top: 0;
    bottom: 40px;
    left: 0;
    right: 0;
  }
  .news_article {
    border: 0;
    margin: 20px 0;
    width: 100%;
  }
  .cover_link:hover {
    background: rgba(0,0,0,0.03);
  }

  .card-title {
    font-size: 24px;
    font-weight: 700;
  }
  .card-footer {
    height: 40px;
  }
  img {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 36px;
    font-weight: 700;
    color: theme-color("primary");
    margin: 20px 0;
  }
  .options {
    width: 100%;
    text-align: right;
    margin: 20px;
  }
}

.admin_link {
  display: inline-block;
  padding: 20px;
}

#unibox-suggest-box {
  a {
    padding: 10px;
  }
  .search_icon, .search_txt {
    display: inline-block;
    padding: 0;
    padding-right: 10px;
  }
  h4 {
    text-align: left;
    margin: 0 !important;
    padding: 10px;
    background: #f8f8f8;
  }

}

.portlet_nav, .portlet_portfolio {
  .cms_module_title {
    display: none;
  }
}

.portlet_content {
  h1 {
    font-family: "Times New Roman";
    color: theme-color("primary");
    padding-top: 30px;
    font-size: 50px;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 46px;
      padding-top: 24px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 42px;
      padding-top: 20px;
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 36px;
      padding-top: 16px;
    }
  }
  h2 {
    font-family: $txt-font;
    color: black;
    padding-top: 25px;
    font-size: 36px;
    font-weight: 600;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 32px;
      padding-top: 22px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 28px;
      padding-top: 20px;
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 26px;
      padding-top: 16px;
    }
  }
  h3 {
    font-family: $txt-font;
    color: black;
    padding-top: 20px;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 22px;
      padding-top: 24px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 20px;
      padding-top: 20px;
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 18px;
      padding-top: 16px;
    }
  }
  h4 {
    font-family: $txt-font;
    color: black;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 20px;
      padding-top: 20px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 18px;
      padding-top: 18px;
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 16px;
      padding-top: 16px;
    }
  }
  h2, h3, h4 {
    line-height: 140%;
  }
}

.cms_module_title{
  font-family: "Times New Roman" !important;
  color: theme-color("primary") !important;
  padding-top: 30px !important;
  font-size: 50px !important;
  font-weight: 400 !important;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    font-size: 46px !important;
    padding: 24px 0;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 42px !important;
    padding: 20px 0;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 36px !important;
    padding: 16px 0;
  }
}

.ui-widget-header {
   border-bottom: none;
   color:theme-color("primary")
 }
.ui-tabs .ui-tabs-nav li {
  @extend .rounded;
  background: $gray-color;
  border:transparent;
  margin-bottom: 2%;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.714;
  a {
    color: black !important;
  }
}
.ui-tabs-nav li.ui-state-default a, .ui-tabs-nav li.ui-state-hover a {
  background: $gray-color;
}
.ui-tabs .ui-tabs-nav {
  list-style-type: none;
}
.ui-tabs-nav li.ui-state-active  {
  @extend .rounded;
  background: theme-color("primary");
}
.ui-tabs-nav li.ui-state-active a {

  color:white !important;
  font-size:14px;
  background: theme-color("primary");
  border:none !important;
  line-height: 1.714;
  font-weight: 400 !important;
}

.ui-tabs-nav li.ui-state-default a {
  color:theme-color("primary");
  font-size:14px;
  text-transform: uppercase;
  border:none;

}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  padding: .5em 1em;
}
.ui-widget-content .ui-state-active, .ui-widget-header {
    border:none;
}

.v1tabs a {
  height: 30px !important;
}
.popupStupac {
  label {
    margin-right: 15px;
  }
  padding: 0 30px !important;
  .input-group-addon {
    padding: 5px;
  }
  input.redirect {
    padding: 10px;
  }
}


.table_nastavnici {
 border-collapse:collapse !important;
 table-layout: fixed !important;


}

.table_nastavnici > tr > th {
 background: #f0f0f0 !important;

}
.table_nastavnici > tbody > tr > td{

border:1px solid $gray-color !important;
text-align: center !important;

  }

.table_nastavnici > tr {
    background: #ffffff;
    text-align: center !important;
    color:$black-color !important;
    font-size: 18px !important;
    line-height: 1.333 !important;
    font-weight: 400;

}

h6.name > a {
color:$black-color !important;
font-size: 18px !important;
line-height: 1.333 !important;
font-weight: 400;
}
.table_nastavnici > tr:nth-child(even) {background-color: #f0f0f0;}


tr.popis_kolumni {

color:$black-color !important;
background-color: $gray-color !important;
font-size: 16px !important;
font-weight: $font-wght-7 !important;
line-height: 1.5 !important;
text-align: center;
@extend .rounded-top;
}
tr.popis_kolumni > th::first-letter {
text-transform: capitalize;
}
tr.popis_kolumni > th.email {
text-transform: lowercase !important;
}

.naziv_grupe {
background: theme-color("primary") !important;
padding:2%;
@extend .rounded;
width:100% !important;
margin-bottom:1% !important;
}
.naziv_grupe > h4 {

  font-size: 24px !important;
  font-weight: $font-wght-7 !important;
  line-height: 1.0 !important;
  color:#FFFFFF !important;
  margin:0;



}

.naziv_grupe > h4::first-letter {
text-transform: capitalize !important;
}


.portlet_predmet_info {
   h3.cms_module_title {
     visibility:  hidden !important;
   }
}



.portlet_portfolio {
  font-size: 16px;
  color: black;
  .portfolio_image {
    max-width:100%;
    height:auto;
    margin-bottom: 20px;
  }
  #person_name {
    font-size: 40px;
    font-weight: 700;
    color: theme-color("primary");
  }
  .admin_toolbox {
    margin: 20px 0;
  }
  table.portfolio {
    width: 100%;
    margin-bottom: 20px;
    tr {
      background: #f7f7f7;
      border-bottom: 5px solid white;
      height: 50px;
      @extend .rounded;
      td {
        font-size: 16px;
        color: black;
        padding: 10px 20px;
        &.data_label {
          font-weight: bold;
        }
      }
    }
  }

  h4 {
    font-size: 32px;
    font-weight: bold;
    background: theme-color("primary");
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    @extend .rounded;
  }
  p.study {
    font-size: 18px;
    background: #f0f0f0;
    @extend .rounded;
    padding: 10px 20px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      padding: 10px 20px;
      &:nth-child(odd) {
        background: #f7f7f7;
      }
    }
  }
}


.studenti_ico {
  font-size: 0 !important;
  letter-spacing: 0 !important;
  word-spacing: 0 !important;
  vertical-align: top;
  text-align: center;
}
.studenti_ico > div {
  vertical-align: top;
  width:180px;
  height:190px;
  padding:2%;
  text-align:center;
  font-size:16px;
  font-weight:600;
  line-height: 1.268;
  color:theme-color("primary");
  display: inline-block;
  img {
    display: block;
    margin: auto;
  }

}
.studenti_ico > div.prazni {
width:180px;
height:190px;


}
.studenti_ico > div.prazni {
@media (max-width: map-get($grid-breakpoints, xl)) {
  @include invisible(hidden);
  width:0;
  height: 0;
  padding:0;
  }
}

.studenti_ico > div > img {
 width:120px !important;
 height:120px !important;

}




#cms_area_left .sidebar .nav-link {
font-size: 16px;
font-weight: 700;
line-height: 1.5;
color: #2C2C2C;
vertical-align: middle;

}

#cms_area_left .sidebar .nav-link:active {
  color:theme-color("primary");
}
#cms_area_left .sidebar li.nav-item a.collapse-link::before {
  font-family: FontAwesome;
  content:"\f107 ";
  display:inline-flex;
  top:50%;
  font-size: 16px;
font-weight: 700;
line-height: 1.5;
color: #2C2C2C !important;
}


#cms_area_left .sidebar li.nav-item a.collapse-link.collapsed::before {
  display:inline-block;
  font-family: FontAwesome;
  content:"\f105";

  font-size: 16px;
font-weight: 700;
line-height: 1.5;
color: #2C2C2C;

}

#cms_area_left .sidebar .nav-link > a.collapsed::before {
  content:"";
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #2C2C2C;


}

#cms_area_left .sidebar .collapse-link, #cms_area_left .sidebar .collapsed-nochildren::before {
color:transparent;

}

#cms_area_left .sidebar .submenu .nav-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.714;
  color: #2C2C2C;


}


#cms_area_left .sidebar .submenu .nav-item.active a{
  color:theme-color("primary");
}

#cms_area_left .sidebar li.nav-link:active a.collapse-link {
  color:theme-color("primary");
}



#area_side_portlets {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    margin: 35px;
  }
}

#area_side_portlets ul.navbar-nav.nav-list li.nav-item {
       font-weight: 700;
       font-size: 16px;
       line-height: 1.5;
       text-transform: uppercase;
       list-style-type: none;
       text-align: left;
       margin-top: -8px;
       margin-left: 34px;

     }

     #area_side_portlets ul.navbar-nav.nav-list li.nav-item a {

      text-align: left !important;
      padding-left: 0.3em;
        text-indent:-0.3em;
     }

#area_side_portlets ul.navbar-nav.nav-list li.nav-item i {
 color:transparent;

}

#cms_area_side_portlets.cms_area {
  justify-content: center !important;

}



#breadcrumbs {
  background: transparent !important;

    ol.breadcrumb {

      padding: 0;

       list-style: none;
      background-color: #fff;
  }
  li.crumb {
    a {
      color:theme-color("primary") !important;
          font-size:14px !important;
          line-height: 1.286 !important;
          font-weight: 600 !important;
    }
    a:hover {
      text-decoration: none !important;
    }
  }
      li.crumb.crumb_root.crumb_site {

        a {
          color:theme-color("primary") !important;
          font-size:14px !important;
          line-height: 1.286 !important;
          font-weight: 600 !important;



        }
        a:hover {
          text-decoration: none !important;
        }




      }



      li.crumb.crumb_site {

        a {
          color:theme-color("primary") !important;
          font-size:14px !important;
          line-height: 1.286 !important;
          font-weight: 600 !important;

         a:hover {
           text-decoration: none !important;
         }


        }


      }
      .breadcrumb>li+li:before {
        padding:0 10px;
        font-family: FontAwesome;
        content:"\f105";
        color:#979797;
        width:6px;
        height: 6px;
      }
      li.crumb.crumb_current {

        a {
          color:theme-color("primary") !important;
          font-size:14px !important;
          line-height: 1.286 !important;
          font-weight: 600 !important;

        }
        a:hover {
          text-decoration: none !important;
        }

      }

    }

.portlet_repository {
  .entry {
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 15px;
  }
  .rep_folder {
    font-weight: 700;
    font-size: 18px;
  }
  .content {
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .arrow {
    font-size: 23px;
  }
  .arrow::after {
    margin-top: 10px;
  }
  h3 {
    padding: 20px 0;
  }
}

.portlet_djelatnici {
  .fullscreen {
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: map-get($grid-breakpoints, md)) {
      display:none;
    }
  }
  ul.hide {
    display: none;
  }
}

.portlet_fpzg_prijava_esej {
  .btn {
    white-space: nowrap;
  }
  .status_ok {
    color: #0c0;
  }
  .status_failed {
    color: #d00;
  }
  .status_pending {
    color: #f80;
  }
  .status_info {
    color: #aaa;
  }

  .student_eseji {
    img.file_icon {
      height: 32px;
    }
    td.legenda {
      width: 200px;
      font-weight: bold;
      text-align: right;
      padding-right: 30px;
    }
    td.middle {
      vertical-align: middle;
    }
  }

  .student_grade {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $primary;
    border: 2px solid $primary;
    font-size: 20px;
    height: 30px;
    width: 30px;
    @extend .rounded-circle;
  }
  .admin_grade {
    display: inline-block;
    font-weight: bold;
    color: $primary;
    font-size: 20px;
    margin-right: 10px;
  }


  .grade_options {
    float: right;
  }

  table.predani_radovi {
    td {
      vertical-align: middle;
    }
    img.file_icon {
      margin-right: 20px;
    }
    .date {
      color: gray;
      font-style: italic;
    }
    .graded {
      display: flex;
      flex-direction: row;
      & > div {
        height: 30px;
        width: 30px;
        background: #f0f0f0;
        @extend .rounded-circle;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        &.checked {
          background: $primary;
          color: white;
          font-weight: bold;
        }
      }
    }
    .lecturer_grade {
      display: flex;
      align-items: center;
      input[type="radio"] {
        display: block;
      }

      label {
        @extend .rounded-circle;
        cursor: pointer;
        background: #f0f0f0;
        margin: 0 5px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 4px;

        &:checked, &:focus, &:focus-within {
          border: 2px solid $primary;
          font-weight: bold;
          color: $primary;
        }
        input[type=radio] {
          opacity: 0;
          height: 4px;
          width: 4px;
          margin: 0;
          padding: 0;
        }
      }
    }
    input[type="submit"] {
      margin: 5px 20px;
    }
  }
}

.footer {
  background-color: #003B66;
  width: 100%;
  color: white;
  padding: 10px 0;
  a {
    color: white;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
  .row > div {
    padding: 10px 0 ;
  }
  .logo {
    img {
      filter: invert(100%) saturate(0%) brightness(200%);
      width: 120px;
    }
  }
  .links1, .links2 {
    font-size: 14px;
    font-weight: bold;
    padding-right: 10px !important;
  }
  .social {
    text-align: right;
    img {
      margin: 0 5px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    .row > div {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

}
.footer_bottom {
  background-color: #00243E;
  width: 100%;
  color: #cccccc;
  padding: 10px 0;
  a {
    color: #cccccc;
  }
  a:hover {
    color: #cccccc;
    text-decoration: none;
  }
  .impressum {
    text-align: right;
    font-weight: 600;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    .impressum {
      text-align: left;
      padding-top: 10px !important;
    }
  }

}

#accessibility_opener {
  display: inline-block;
  img {
    display: none
  }
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: relative;
  top: -13px;
  background: url('../../../icons/accessibility.png');
  background-size: cover;
  //position: absolute;
}


.accessibility_dialog {
  @extend .shadow;
  max-width: 320px;
  width: 320px !important;
  background: white;
  padding: 15px;
  border: none;
  font-family: inherit;
  z-index: 9999;
  .ui-dialog-title{
    text-transform: uppercase;
  }
  .ui-dialog-titlebar {
    border: none !important;
  }
  .ui-dialog-titlebar-close {
    @extend .rounded;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    border: none;
    background: theme-color("primary") url('../../../icons/close.png') center no-repeat;
    -webkit-background-size: 10px;
    background-size: 10px;
  }
  #accessibility_links {
    max-width: 280px;
    .textsize {
      .gornjilinkoviboxVelicina {
        padding: 10px;
        font-weight: 800;
      }
      .select_size_0 {
        font-size: 11px !important;
      }
      .select_size_1 {
        font-size: 13px !important;
      }
      .select_size_2 {
        font-size: 15px !important;
      }
    }
    .highcontrast {
      .highcontrast_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
    .simplelayout {
      .simplelayout_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
  }
}

//Accessibility text size:
body.textsize-big {
  font-size: 20px;

  .accessibility_links, .ui-dialog-title {
    font-size: 16px !important;
    }
  .login_icon .login_link .fa-unlock-alt {
    font-size: 16px;
  }

  .top_link_row > .top_link{
    .fa {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }

  #cms_area_horizontal_nav .portlet_nav a {
    font-size: 20px;
  }
  #cms_area_right, #cms_area_left .portlet_nav .nav-element {
    .nav_link {
      font-size: 22px !important;
      padding: 8px;
    }
    li > ul li {
      padding-top: 0;
    }
    ul > li > a {
      font-size: 22px !important;
      padding: 8px;
    }
  }
  #breadcrumbs li.crumb.crumb_current a, #breadcrumbs li.crumb.crumb_root.crumb_site a {
    font-size: 16px;
  }
  #cms_area_left .sidebar .nav-link {
    font-size: 18px;
  }
  #cms_area_left .sidebar .submenu .nav-link {
    font-size: 16px;
  }

  .cms_module_title {
    font-size: 24px;
  }

  .portlet_news {
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 24px !important;
      }
    }
  }
  .sidepanel .language span.w3c-lang-name {
    font-size: 16px;
    padding: 4px;
  }
  .sidepanel .login a {
    font-size: 20px;
    top: 6px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 56px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 30px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p a{
    font-size: 30px !important;
  }

  #cms_area_middle .portlet_news_show_headlines h5, #cms_area_onecol_white .portlet_news_show_headlines h5 {
    font-size: 1.70rem;
  }

  #cms_area_white_left .portlet_content h2 {
    font-size: 2rem;
  }
  .fixed_header .fixed_nav div.faculty_name {
    font-size:20px;
  }
  .fixed_header {
    font-size: 18px;
  }

  h5 {
    font-size: 1.7rem;
  }
  .news_show_headlines_render_articles .card-body {
    font-size: 20px;
  }

  footer, footer .social {
    font-size: 18px;
  }
  footer h4 {
    font-size: 20px;
  }
  nav.main_nav li {
    font-size: 18px;
  }

  nav.main_nav .submenu ul .nav-link {
    font-size: 16px;
  }

  .news_show_headlines_render_articles .card .card-content {
    overflow: hidden;
  }
}//end of .textsize-big
//END accessibility text size

//accessibility simplelayout
body.simplelayout {
  .carousel {
    display: none;
  }
  #cms_area_middle_right {
    .portlet_news_show_headlines {
      script {
        display: none;
      }
    }
  }
  .col-lg-4 {
    flex: unset;
    max-width: 100%;
    width: 100%;
  }

  .news_show_headlines_render_articles .col-md-4.col-sm-12.pt-4 {
    width: 100%;
    flex: unset;
    max-width: 100%;
  }
}
//END accessibility simplelayout

//accessibility contrast
body.highcontrast-normal {
  color: black !important;
  a {
    color: darken($primary, 50%);
  }
  .header_columns {
    color: black;
  }
  .top_link_row > .top_link a {
    color: black;
  }
  .accessibility_dialog {
    color: black;
    .ui-dialog-title{
      color: black;
    }
    .ui-dialog-titlebar-close {
      color: black;
    }
  }
  .horizontal-nav-row {
    color: black !important;
  }
  .portlet_nav {
    color: black !important;
    .nav > li > a {
      color: black !important;
    }
    .nav > li > ul > li a {
      color: black !important;
    }
  }
  .header-search_input {
    border: 1px solid $border-color !important;
    color: black;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: black !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: black !important;
      .truncate-to-visible {
        color: black !important;
      }
    }
  }
  .carousel-caption {
    color: black !important;
    .truncate-to-visible {
      color: black !important;
    }
  }
  .item .carousel-caption .title a {
    color: black !important;
  }
  .item .carousel-caption h1 a {
    color: black !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: black !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: black !important;
    font-weight: 800;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: black !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: black !important;
  }
  .footer a {
    color: #FFF !important;
  }
  .mega-dropdown-menu {
    //background: white !important;
  }
  .search-button, .lang_link {
    color: white !important;
  }
  .div_login img, .hamburger img {
   filter: invert(100%);
  }
  .mm-listview>li>a, .mm-listview>li>span {
    color: black !important;
  }
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:active, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:focus, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu  a:hover, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:visited {
     //color: black !important;
  }
  .header .header-icons .search-separator .search-container input {
     border: 1px solid black;
     background-color: white !important;
     color: black !important;
  }

  .fixed_header, .news_show_headlines_render_articles .card-footer {
    color: #000 !important;
  }

  .fixed_header .quilt_nav ul.navbar-nav .mega-dropdown-menu a {
    color: #fff !important;
  }
  #breadcrumbs li.crumb a, #breadcrumbs li.crumb.crumb_root.crumb_site a {
    color: black !important;
  }

  .footer_bottom {
    color: white !important;
  }
}
//END accessibility contrast


//accessibility inverted contrast
body.highcontrast-inverted {
  color: white !important;
  background-color: #777;
  a {
    color:  white !important;
  }
  .popup_menu {
    background-color: #777 !important;
  }
  .portlet_admin_traka {
    .dropdown-menu {
      background-color: #777 !important;
      > li > a {
        background-color: #777 !important;
      }
    }
  }
  .logo_div {
    img.normal {
      display: none;
    }
    img.white {
      display: block;
    }
  }
  .header_columns {
    color: white !important;
  }
  .fixed_header {
    background-color: #333 !important;
  }
  #area_onecol_white, #area_onecol_silver, .twocol_side {
    background-color: #333;
  }
  .news_show_headlines_render_articles .card {
    background-color: #333;
  }
  .above_header .header_logo {
    filter: invert(1);
  }
  .sidepanel {
    background-color: #333 !important;
  }
  .sidepanel .col-12.col-md-12.col-lg-8 .bg-light, .sidepanel .col-12.col-md-12.col-lg-4 {
    background-color: #333 !important;
  }
  .sidepanel .language .current_lang span.w3c-lang-name, .sidepanel .language span.w3c-lang-name {
    color: white !important;
    border: 1px solid white !important;
  }
  .top_link_row > .top_link a {
    color: white !important;
  }
  .ui-widget-content {
    background-color: #777 !important;
  }
  .accessibility_dialog {
    span {
      color: white !important;
      font-weight: 800;
    }
    color: white !important;
    .ui-dialog-title{
      color: white !important;
    }
    .ui-dialog-titlebar-close {
      //color: white !important;
    }
  }
  .horizontal-nav-row {
    color: white !important;
  }
  .portlet_nav {
    color: white !important;
    background-color: #333;

    .nav > li > a {
      color: white !important;
    }
    .nav > li > ul > li a {
      color: white !important;
    }
    .nav {
      background-color: transparent !important;
    }
  }
  .nav ul li, .nav ul li:hover {
    background-color: #333 !important;
  }

  #cms_area_left .portlet_nav .nav_link:hover, #cms_area_left .portlet_nav .collapse-link:hover,  #cms_area_left .portlet_nav .nav-element .collapse-link:hover, #cms_area_left .portlet_nav .nav-element:hover {
    background-color: #333 !important;
  }
  div.repository .open, div.repository .closed {
    background-color: #333;
  }
  div.repository image {
    filter: invert(1);
  }
  div.repository .closed div.arrow, div.repository div.open div.arrow {
    color: white;
  }
  div.portlet_repository div.content {
    color: lightgrey;
  }
  #v1tooltip_div {
    background-color: #333;
  }
  .portlet_subscription .subscicons, .portlet_subscription .subs_module {
    color: white !important;
  }
  .portlet_predmet_info .col-md-3 p, .portlet_predmet_info .opterecenje_info, .portlet_predmet_info div em {
    color: white !important;
  }
  .staff_group, .portlet_djelatnici .person {
    color: white;
  }
  .header-search_input {
    border: 2px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: white !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: white !important;
      .truncate-to-visible {
        color: white !important;
      }
    }
  }
  .carousel-caption {
    color: white !important;
    .truncate-to-visible {
      color: white !important;
    }
  }
  .carousel {
    border-color:  #777 !important;
    .item {
      background-color: #777 !important;
    }
  }
  input, select {
    //border: 2px solid @brand-primary;
    //background-color: @brand-primary;
    color:  darken($border-color, 60%);
  }
  tr {
    background-color: darken($border-color, 60%)  !important;
    color: white;
  }
  tr:hover {
    background-color: darken($border-color, 60%)  !important;
    color: white !important;
    a, a:hover, a:active, a:focus {
      color: white !important;
      font-weight: 800 !important;
    }
  }
  tr:nth-of-type(2n+1) {
    background-color: darken($border-color, 30%)  !important;
    color: white;
  }
  tr:nth-of-type(2n+1):hover {
    background-color: darken($border-color, 30%)  !important;
    color: white;
    a, a:hover, a:active, a:focus {
      font-weight: 800 !important;
    }
  }
  .predmetispis_lighter {
    color: white;
  }
  .predmetispis_lighter:hover {
    font-weight: bold;
  }
  .item .carousel-caption .title a {
    color: white !important;
  }
  .item .carousel-caption h1 a {
    color: white !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: white !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: white !important;
    font-weight: 800;
    background-color: #333 !important;
  }
  .portlet_news_show_headlines .news_headlines .article .thumbnail {
    background-color: transparent !important;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: white !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: white !important;
  }
  #footer_container {
    background-color: transparent;
  }
  #footer_container #footer_header {
    color: white !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: white !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: white !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: white !important;
  }

  .gray, .white, .blue, .footer, .white-layer, .mega-dropdown-menu, .gradient, #mmenu_init {
      background-color: #333 !important;
  }
  .fixed_header .quilt_nav, .col-lg-4, .portlet_news .news_articles .card {
    background: #333;
  }

  nav.main_nav .submenu ul .submenu ul .links:hover, nav.main_nav .submenu ul .links:hover, .nav-link:focus, .nav-link:hover {
    background: #333;
  }
  .scrollable_content {
    background: #777;
  }
  #cms_area_left nav.main_nav {
    & > ul > li > .links:hover {
      background-color: #333;
    }
  }

  .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-default, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active:hover, .cms_module .ui-tabs .ui-tabs-nav li.ui-state-default:hover {
    background-color: #333 !important;
  }

  .cms_module .ui-tabs .ui-tabs-nav li.ui-state-active {
    background: #333 !important;
  }

  .scrollable_content .scrollable_white {
    background: #333 !important;
  }
  .portlet_content .cms_module_title, #breadcrumbs ol.breadcrumb, .fixed_header .fixed_nav {
    background: #777;
  }
  #breadcrumbs li.crumb a, #breadcrumbs li.crumb.crumb_root.crumb_site a {
    color: white !important;
  }

  .scrollable_content .scrollable_white .area_quicklinks ul li a {
    background: #777 !important;
  }
  .footer_bottom {
    background: #777;
    color: white !important;
  }

  .scrollable_content .scrollable_white .area_logolinks {
    background: #777;
  }
}
//END accessibility inverted contrast

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.cookie-message-container {
  background: rgba(32,32,32,.9);
  padding: 10px 20px;
  color: #fff;
}
